import { Submittal } from "../Submittal/submittalTypes";

/**
 * Get base of current url => https://app.getplot.com/projects -> https://app.getplot.com
 */
export function baseUrlWithPort(): string {
    return window.location.origin;
}

/**
 * Get the base of current url with an alternate subdomain => https://app.getplot.com/projects -> https://projects.getplot.com
 * @param alt_subdomain alternate subdomain to use
 * @returns
 */
export function baseUrlAltSubdomain(alt_subdomain: string): string {
    const hostname_parts = window.location.host.split(".");
    const new_hostname = [alt_subdomain, hostname_parts[1], hostname_parts[2]].join(".");
    return window.location.protocol + "//" + new_hostname;
}

/**
 * Get the current urls subdomain
 * @returns string of current url subdomain
 */
export function currentUrlSubdomain(): string {
    return window.location.host.split(".")[0];
}

/**
 * Get a URL that redirects to login and then returns back to current page on success
 * @returns URL mapping to login with return url of current url
 */
export function currentUrlRedirectToLogin(): string {
    return urlWithRedirectToLogin(window.location.href);
}

/**
 *
 * @param
 * @returns
 */
export function currentUrlRedirectToSignup(): string {
    return urlWithRedirectToSignup(window.location.href);
}

/**
 * Get a URL that redirects to login before going to the desired page on success
 * @param url
 * @returns URL mapping to login with return url of passed url
 */
export function urlWithRedirectToLogin(url: string): string {
    return baseUrlWithPort() + "/login?return_url=" + url;
}

export function urlWithRedirectToSignup(url: string): string {
    return baseUrlWithPort() + "/signup?return_url=" + url;
}

/**
 * Get the current URL without any added params at the end
 * @returns URL without params
 */
export function currentUrlNoParams(): string {
    return window.location.origin + window.location.pathname;
}

/**
 *
 */
export function homeURLRedirect(): string {
    return baseUrlWithPort() + "/home";
}

export function getSignUpUrl(project_token: string | null, viewed_team: number | null) {
    //Hard coded this instead of fixing routes and redirects. Weird redirect stuff between projects and app subdomain but this works to redirect user BACK to signup flow after signing in.
    let url = `${baseUrlAltSubdomain("project")}/login?return_url=${window.location.href}`;
    if (project_token) {
        url += `&project_token=${project_token}&`;
    }
    if (viewed_team) {
        url += `&team_id=${viewed_team}`;
    }
    return url;
}

/**
 * Will retry a given promise a given number of times as long as that promise returns null, and return the result or null if failed
 * @param func some promised to be repeated
 * @param retryCount how many times to repeat this promise
 * @returns return value of the promise or null if promise returns null
 */
export function retryPromise(func: () => Promise<any>, retryCount: number = 3): Promise<any> {
    if (retryCount <= 0) {
        return Promise.resolve<null>(null);
    }

    return func().then((result) => {
        if (result) {
            return result;
        }

        return retryPromise(func, --retryCount);
    });
}

export function isValidUrl(urlString: string) {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(urlString);
}

export function isHTMLBlock(str) {
    const a = document.createElement("div");
    a.innerHTML = str;

    for (let c = a.childNodes, i = c.length; i--; ) {
        if (c[i].nodeType == 1) return true;
    }

    return false;
}

export function integrationSubmittalUrlBuilder(projectInfo: any, submittal: Submittal): string {
    if (projectInfo.integration_source === "procore_sandbox") {
        return `https://sandbox.procore.com/webclients/host/companies/${projectInfo.integration_source_company_id}/projects/${projectInfo.integration_source_project_id}/tools/submittals/${submittal.vendor_cache?.vendor_record_id}`;
    } else if (projectInfo.integration_source === "procore") {
        return `https://us02.procore.com/webclients/host/companies/${projectInfo.integration_source_company_id}/projects/${projectInfo.integration_source_project_id}/tools/submittals/${submittal.vendor_cache?.vendor_record_id}`;
    } else if (projectInfo.integration_source === "autodesk") {
        return `https://acc.autodesk.com/build/submittals/projects/${projectInfo.integration_source_project_id}/items/${submittal.id}`;
    } else return "";
}
