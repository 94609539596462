import { Cable, Channel, createCable } from "@anycable/web";
import * as Sentry from "@sentry/react";
import { notification } from "antd";
import { useEffect, useState } from "react";
import { baseUrlAltSubdomain } from "../components/Helper/Url";
import { Delivery } from "../models/Delivery";

interface useAnyCableProps {
    projectInfo: any | null;
    currentTeamsProjects: any | null;
    setNewBroadcastMessage: (value: boolean) => void;
    updateSingleDelivery: (project_token: string, delivery: Delivery) => void;
    deliveriesByProject: { unique_token: string; deliveries: Delivery[] }[];
}

interface DeliveryMessage {
    method: "update";
    type: "delivery";
    project: any;
    delivery: any;
    status:
        | "declined"
        | "requested_override"
        | "pending"
        | "ordered"
        | "scheduled"
        | "updated"
        | "confirmed"
        | "en_route"
        | "arrived"
        | "departed";
    action: "reload";
}

const useAnyCable = (props: useAnyCableProps) => {
    const [cableApp, setCableApp] = useState<Cable | null>(null);
    const [dashboardNotificationsChannel, setDashboardNotificationsChannel] = useState<Channel | null>(null);
    const [mapChannel, setMapChannel] = useState<Channel | null>(null);
    const [deliveriesChannel, setDeliveriesChannel] = useState<Channel | null>(null);
    const [broadcastsChannel, setBroadcastsChannel] = useState<Channel | null>(null);

    useEffect(() => {
        const appUrl = baseUrlAltSubdomain("app") + "/cable";
        const cableUrl = appUrl.replace("https://", "wss://").replace("http://", "ws://");

        setCableApp(createCable(cableUrl));

        return () => {
            try {
                dashboardNotificationsChannel?.disconnect();
                mapChannel?.disconnect();
                deliveriesChannel?.disconnect();
                broadcastsChannel?.disconnect();
                cableApp?.disconnect();
            } catch (error) {
                Sentry.captureException(error);
            }
        };
    }, []);

    useEffect(() => {
        if (!cableApp || !props.projectInfo) return;

        createMapChannel();
        createDashboardNotificationsChannel();
        createBroadcastChannel();
    }, [cableApp, props.projectInfo]);

    useEffect(() => {
        createDeliveryChannel();
    }, [props.projectInfo, props.deliveriesByProject, cableApp]);

    const createDeliveryChannel = () => {
        if (!cableApp || !props.projectInfo || !props.deliveriesByProject) {
            return;
        }

        if (deliveriesChannel) {
            try {
                deliveriesChannel.disconnect();
            } catch (error) {
                Sentry.captureException(error);
            }
            setDeliveriesChannel(null);
        }

        const newDeliveryChannel = cableApp.subscribeTo("DeliveriesChannel", {
            project_unique_token: props.projectInfo.unique_token,
        });

        newDeliveryChannel.on("message", (data: any) => {
            if (data.method == "update" && data.type == "delivery") {
                const delivery = Delivery.fromApiObject(data.delivery, props.projectInfo.timezone);

                props.updateSingleDelivery(data.project_unique_token, delivery);
            }
        });

        newDeliveryChannel.on("disconnect", (data) => {
            setDeliveriesChannel(null);
        });

        setDeliveriesChannel(newDeliveryChannel);
    };

    const createMapChannel = () => {
        if (mapChannel) {
            try {
                mapChannel.disconnect();
            } catch (error) {
                Sentry.captureException(error);
            }
            setMapChannel(null);
        }

        const newMapChannel = cableApp?.subscribeTo("MapChannel", {
            project_unique_token: props.projectInfo.unique_token,
        });

        newMapChannel?.on("disconnect", (data) => {
            setMapChannel(null);
        });

        setMapChannel(newMapChannel || null);
    };

    const createDashboardNotificationsChannel = () => {
        if (dashboardNotificationsChannel) {
            try {
                dashboardNotificationsChannel.disconnect();
            } catch (error) {
                Sentry.captureException(error);
            }
        }

        const newDashboardNotificationsChannel = cableApp?.subscribeTo("DashboardNotificationsChannel");

        newDashboardNotificationsChannel?.on("disconnect", (data) => {
            setDashboardNotificationsChannel(null);
        });

        setDashboardNotificationsChannel(newDashboardNotificationsChannel || null);
    };

    const createBroadcastChannel = () => {
        if (broadcastsChannel) {
            try {
                broadcastsChannel.disconnect();
            } catch (error) {
                Sentry.captureException(error);
            }
            setBroadcastsChannel(null);
        }
        const newBroadcastsChannel = cableApp?.subscribeTo("BroadcastsChannel", {
            project_unique_token: props.projectInfo.unique_token,
        });
        if (newBroadcastsChannel) {
            newBroadcastsChannel?.on("message", (data) => {
                props.setNewBroadcastMessage(true);
                notification.warning({
                    message: `New Jobsite Alert from ${data.user.fullname}`,
                    description: data.message.body,
                    duration: 10,
                    placement: "topLeft",
                });
            });
        }

        newBroadcastsChannel?.on("disconnect", (data) => {
            setBroadcastsChannel(null);
        });

        setBroadcastsChannel(newBroadcastsChannel || null);
    };

    return {
        cableApp,
        dashboardNotificationsChannel,
        mapChannel,
        deliveriesChannel,
    };
};

export default useAnyCable;
