import { MaterialGroupModel } from "./MaterialGroup.model";

export class MaterialGroup extends MaterialGroupModel {
    getNextOrderBy(): Date | null {
        const validOrderBys =
            this.materials
                ?.filter((material) => !material.archived)
                ?.map((material) => material.getNextOrderBy())
                ?.filter((order_by) => order_by != null) || [];

        if (validOrderBys.length > 0) {
            const sortedOrderBys = validOrderBys.sort((a, b) => a.getTime() - b.getTime());

            return sortedOrderBys[0];
        }

        return null;
    }

    getNextROJ(): Date | null {
        const validROJs =
            this.materials
                ?.filter((material) => !material.archived)
                ?.map((material) => material.getNextROJ())
                ?.filter((roj) => roj != null) || [];

        if (validROJs) {
            const sortedROJs = validROJs.sort((a, b) => a.getTime() - b.getTime());

            return sortedROJs[0];
        }

        return null;
    }
}
