import DeliveriesFilterInfo from "../../context/deliveriesFilterinfo";
import Helper from "../../Helper";
import { Delivery } from "../../models/Delivery";
import { plotFetch } from "../Helper/ApiRequest";

export function getProjectDeliveries(
    team_subdomain: string,
    project_token: string,
    filterInfo: DeliveriesFilterInfo,
    timezone: string
): Promise<Delivery[]> {
    const url = `
        ${Helper.baseUrlAltSubdomain(
            team_subdomain ? team_subdomain : "projects"
        )}/projects/${project_token}/deliveries?${filterInfo.toQueryString()}`;

    return plotFetch(url, { method: "GET" })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return [];
        })
        .then((response) => {
            return response.map((delivery: any) => Delivery.fromApiObject(delivery, timezone));
        })
        .catch((error) => {
            return [];
        });
}

export function createDelivery(
    team_subdomain: string,
    project_token: string,
    delivery: Delivery,
    is_repeating?: boolean
): Promise<Delivery> {
    const url = new URL(`${Helper.baseUrlAltSubdomain(team_subdomain)}/projects/${project_token}/deliveries`);

    if (is_repeating) {
        url.searchParams.append("repeating", "true");
    }

    return plotFetch(
        url.toString(),
        {
            method: "post",
            body: JSON.stringify({ delivery: delivery.toApiObject() }),
        },
        true
    )
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data;
        })
        .catch(() => {
            return null;
        });
}

export function updateDelivery(
    team_subdomain: string,
    project_token: string,
    delivery: Delivery
): Promise<Delivery | null> {
    return plotFetch(
        `${Helper.baseUrlAltSubdomain(team_subdomain)}/projects/${project_token}/deliveries/${delivery.uniqueToken}`,
        {
            method: "put",
            body: JSON.stringify({ delivery: delivery.toApiObject() }),
        },
        true
    )
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data;
        })
        .catch(() => {
            return null;
        });
}

export function getDelivery(
    team_subdomain: string,
    project_token: string,
    delivery_token: string,
    timezone: string
): Promise<Delivery | null> {
    return plotFetch(
        `${Helper.baseUrlAltSubdomain(team_subdomain)}/projects/${project_token}/deliveries/${delivery_token}`,
        { method: "GET" }
    )
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error("Network error.");
        })
        .then((response) => {
            return Delivery.fromApiObject(response, timezone);
        })
        .catch(() => {
            return null;
        });
}
