import { MaterialModel } from "./Material.model";
import { Order } from "./Order";

export class Material extends MaterialModel {
    // Get the orders that are in need of action, aka. need user input to make sure they are on track
    ordersInNeedOfAction(): Order[] {
        return this.orders?.filter((order) => !order.archived && order.cushion && order.cushion < 0) || [];
    }

    getNextOrderBy(): Date | null {
        const unorderedOrders =
            this.orders?.filter((order) => !order.ordered && order.orderBy && !order.archived) || [];

        if (unorderedOrders.length > 0) {
            const sortedOrders = unorderedOrders.sort(
                // @ts-expect-error: Typescript is not smart enough to know that I have filtered out any that would not end up with a calculated order by
                (a, b) => a.orderBy?.toDate()?.getTime() - b.orderBy?.toDate()?.getTime()
            );

            // @ts-expect-error: Typescript is not smart enough to know that I have filtered out any that would not end up with a calculated order by
            const orderBy: Date = sortedOrders[0].orderBy?.toDate();

            return orderBy;
        }

        return null;
    }

    getNextROJ(): Date | null {
        const noDeliveryScheduledOrders =
            this.orders?.filter((order) => order.requiredOnJobsite && !order.delivery && !order.archived) || [];

        if (noDeliveryScheduledOrders.length > 0) {
            const sortedOrders = noDeliveryScheduledOrders.sort(
                // @ts-expect-error: Typescript is not smart enough to know that I have filtered out any that would not end up with a required_on_jobsite
                (a, b) => a.requiredOnJobsite?.toDate()?.getTime() - b.requiredOnJobsite?.toDate()?.getTime()
            );

            // @ts-expect-error: Typescript is not smart enough to know that I have filtered out any that would not end up with a required_on_jobsite
            const dateNeeded: Date = sortedOrders[0].requiredOnJobsite.toDate();

            return dateNeeded;
        }

        return null;
    }
}
