/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Order.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Order } from "./Order";

import { ActivitiesOrder } from "./ActivitiesOrder";
import { Audit } from "./Audit";
import { Comment } from "./Comment";
import { Delivery } from "./Delivery";
import { Material } from "./Material";
import { OrderCheckIn } from "./OrderCheckIn";
import { Project } from "./Project";

export type status = "needs_roj" | "needs_lead_time" | "needs_material_submitted" | "needs_material_approved" | "needs_ordered" | "needs_scheduled" | "needs_received" | "complete";
export type leadTimeUnitType = "days" | "weeks" | "months" | "years";

export const statusValues = {"needs_roj":5,"needs_lead_time":10,"needs_material_submitted":15,"needs_material_approved":20,"needs_ordered":25,"needs_scheduled":30,"needs_received":35,"complete":40};
export const leadTimeUnitTypeValues = {"days":0,"weeks":1,"months":2,"years":3};

export abstract class OrderModel {
    id: number;
    materialId: number | null;
    deliveryId: number | null;
    orderBy: Dayjs | null;
    ordered: Dayjs | null;
    orderedUpdated: Dayjs | null;
    requiredOnJobsite: Dayjs | null;
    requiredOnJobsiteUpdated: Dayjs | null;
    orderName: string | null;
    quantity: number | null;
    leadTime: number | null;
    leadTimeUpdated: Dayjs | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    activityIds: string | null;
    shakeoutDays: number | null;
    checkInFrequency: number | null;
    archived: boolean | null;
    anticipatedOnJobsite: Dayjs | null;
    cushion: number | null;
    status: status;
    projectId: number | null;
    serialNumber: number | null;
    needsCheckIn: boolean;
    anticipatedOrder: Dayjs | null;
    leadTimeUnitType: leadTimeUnitType;
    submitBy: Dayjs | null;
    approveBy: Dayjs | null;
    comments: Comment[] | undefined;
    audits: Audit[] | undefined;
    project: Project | null | undefined;
    material: Material | null | undefined;
    delivery: Delivery | null | undefined;
    activitiesOrders: ActivitiesOrder[] | undefined;
    orderCheckIns: OrderCheckIn[] | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        materialId: number | null,
        deliveryId: number | null,
        orderBy: Dayjs | null,
        ordered: Dayjs | null,
        orderedUpdated: Dayjs | null,
        requiredOnJobsite: Dayjs | null,
        requiredOnJobsiteUpdated: Dayjs | null,
        orderName: string | null,
        quantity: number | null,
        leadTime: number | null,
        leadTimeUpdated: Dayjs | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        activityIds: string | null,
        shakeoutDays: number | null,
        checkInFrequency: number | null,
        archived: boolean | null,
        anticipatedOnJobsite: Dayjs | null,
        cushion: number | null,
        status: status,
        projectId: number | null,
        serialNumber: number | null,
        needsCheckIn: boolean,
        anticipatedOrder: Dayjs | null,
        leadTimeUnitType: leadTimeUnitType,
        submitBy: Dayjs | null,
        approveBy: Dayjs | null,
        comments: Comment[] | undefined,
        audits: Audit[] | undefined,
        project: Project | null | undefined,
        material: Material | null | undefined,
        delivery: Delivery | null | undefined,
        activitiesOrders: ActivitiesOrder[] | undefined,
        orderCheckIns: OrderCheckIn[] | undefined
    ) {
        this.id = id;
        this.materialId = materialId;
        this.deliveryId = deliveryId;
        this.orderBy = orderBy;
        this.ordered = ordered;
        this.orderedUpdated = orderedUpdated;
        this.requiredOnJobsite = requiredOnJobsite;
        this.requiredOnJobsiteUpdated = requiredOnJobsiteUpdated;
        this.orderName = orderName;
        this.quantity = quantity;
        this.leadTime = leadTime;
        this.leadTimeUpdated = leadTimeUpdated;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.activityIds = activityIds;
        this.shakeoutDays = shakeoutDays;
        this.checkInFrequency = checkInFrequency;
        this.archived = archived;
        this.anticipatedOnJobsite = anticipatedOnJobsite;
        this.cushion = cushion;
        this.status = status;
        this.projectId = projectId;
        this.serialNumber = serialNumber;
        this.needsCheckIn = needsCheckIn;
        this.anticipatedOrder = anticipatedOrder;
        this.leadTimeUnitType = leadTimeUnitType;
        this.submitBy = submitBy;
        this.approveBy = approveBy;
        this.comments = comments;
        this.audits = audits;
        this.project = project;
        this.material = material;
        this.delivery = delivery;
        this.activitiesOrders = activitiesOrders;
        this.orderCheckIns = orderCheckIns;
    }
     

    static fromApiObject(json: any, timezone: string): Order {
        const newObject = new Order(
            json["id"],
            json["material_id"],
            json["delivery_id"],
            json["order_by"] ? dayjs(json["order_by"]).tz(timezone) : null,
            json["ordered"] ? dayjs(json["ordered"]).tz(timezone) : null,
            json["ordered_updated"] ? dayjs(json["ordered_updated"]).tz(timezone) : null,
            json["required_on_jobsite"] ? dayjs(json["required_on_jobsite"]).tz(timezone) : null,
            json["required_on_jobsite_updated"] ? dayjs(json["required_on_jobsite_updated"]).tz(timezone) : null,
            json["order_name"],
            json["quantity"],
            json["lead_time"],
            json["lead_time_updated"] ? dayjs(json["lead_time_updated"]).tz(timezone) : null,
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["activity_ids"],
            json["shakeout_days"],
            json["check_in_frequency"],
            json["archived"],
            json["anticipated_on_jobsite"] ? dayjs(json["anticipated_on_jobsite"]).tz(timezone) : null,
            json["cushion"],
            json["status"],
            json["project_id"],
            json["serial_number"],
            json["needs_check_in"],
            json["anticipated_order"] ? dayjs(json["anticipated_order"]).tz(timezone) : null,
            json["lead_time_unit_type"],
            json["submit_by"] ? dayjs(json["submit_by"]).tz(timezone) : null,
            json["approve_by"] ? dayjs(json["approve_by"]).tz(timezone) : null,
            json["comments"]?.map((item: any) => Comment.fromApiObject(item, timezone)),
            json["audits"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone),
            json["material"] == null ? json["material"] : Material.fromApiObject(json["material"], timezone),
            json["delivery"] == null ? json["delivery"] : Delivery.fromApiObject(json["delivery"], timezone),
            json["activities_orders"]?.map((item: any) => ActivitiesOrder.fromApiObject(item, timezone)),
            json["order_check_ins"]?.map((item: any) => OrderCheckIn.fromApiObject(item, timezone))
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            material_id: this.materialId,
            delivery_id: this.deliveryId,
            order_by: this.orderBy,
            ordered: this.ordered,
            ordered_updated: this.orderedUpdated,
            required_on_jobsite: this.requiredOnJobsite,
            required_on_jobsite_updated: this.requiredOnJobsiteUpdated,
            order_name: this.orderName,
            quantity: this.quantity,
            lead_time: this.leadTime,
            lead_time_updated: this.leadTimeUpdated,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            activity_ids: this.activityIds,
            shakeout_days: this.shakeoutDays,
            check_in_frequency: this.checkInFrequency,
            archived: this.archived,
            anticipated_on_jobsite: this.anticipatedOnJobsite,
            cushion: this.cushion,
            status: this.status,
            project_id: this.projectId,
            serial_number: this.serialNumber,
            needs_check_in: this.needsCheckIn,
            anticipated_order: this.anticipatedOrder,
            lead_time_unit_type: this.leadTimeUnitType,
            submit_by: this.submitBy,
            approve_by: this.approveBy,
            comments: this.comments?.map(item => item.toApiObject()),
            audits: this.audits?.map(item => item.toApiObject()),
            activities_orders_attributes: this.activitiesOrders?.map(item => item.toApiObject()),
            order_check_ins: this.orderCheckIns?.map(item => item.toApiObject()),
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Order {
        const clone = Object.create(this) as Order;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
