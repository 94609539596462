import React, { lazy, Suspense, useEffect, useState } from "react";

import { faTv } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Layout } from "antd";
import GlobalStoreContext from "../../context/GlobalStoreContext";
import Helper from "../../Helper";
import Permission from "../../Permission";
import TeamInfo from "../../teamInfo";
import ProjectSelect from "../Sidebar/projectSelect";
import AcceptInviteModal from "../Team/acceptInviteModal";
import TeamAvatar from "../Team/teamAvatar";
import SidebarControlButton from "../UI/SidebarButton";
import AddTeamToProjectModal from "./addTeamToProjectModal";
import InstallPLOTModal from "./installPLOTModal";
import RequestToJoinProject from "./RequestToJoinProject";
const ProjectSettings = lazy(() => import("./ProjectSettings/projectSettings"));

const AcceptAutoJoinToProjectModal = lazy(() => import("./acceptAutoJoinToProjectModal"));
const LeadTimesPage = lazy(() => import("../../views/leadTimesPage"));
const NotificationsList = lazy(() => import("../Chat/NotificationsList"));
const ProjectCalendar = lazy(() => import("../Calendar/calendar"));
const ProjectLogistics = lazy(() => import("./projectLogistics"));
const ProjectMembers = lazy(() => import("./projectMembers"));
const ProjectPortal = lazy(() => import("./ProjectPortal"));
const PromptJobsiteActiveModal = lazy(() => import("./promptJobsiteActive"));
const PromptForNotificationPreferencesModal = lazy(() => import("../Profile/PromptForNotificationPreferencesModal"));

const { Content } = Layout;

interface ProjectProps {
    objectId?: string;
    eventId?: string;
    page?: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className={Helper.classNames(value !== index ? "invisible" : "tab-panel flex flex-1 h-full")}
        >
            {value === index && (
                <Box sx={{ p: 3 }} className="flex-1">
                    <div className="h-full add-padding flex w-full">{children}</div>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Project: React.FC<ProjectProps> = (props) => {
    const context = React.useContext(GlobalStoreContext);

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [unreadMentions, setUnreadMentions] = useState<number>(0);
    const [anchorElement, setAnchorElement] = useState(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [openShareModal, setOpenShareModal] = useState<boolean>(false);
    const [openJoinModal, setOpenJoinModal] = useState<boolean>(false);
    const [guestToken, setGuestToken] = useState<string | null>(null);
    const [autoApproveToken, setAutoApproveToken] = useState<string | null>(null);
    const [promptedForSetActive, setPromptedForSetActive] = useState<boolean>(false);
    const [activePromptVisible, setActivePromptVisible] = useState<boolean>(false);
    const [redirectedForLoggedIn, setRedirectedForLoggedIn] = useState<boolean>(false);
    const [installPLOTModalVisible, setInstallPLOTModalVisible] = useState<boolean>(false);
    const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
    const [newWeatherAlertEnabled, setNewWeatherAlertEnabled] = useState<any[]>([]);
    const [weatherAlerts, setWeatherAlerts] = useState<any[]>([]);
    const [showProjectProfile, setShowProjectProfile] = useState<boolean>(false);
    const [mtTeam, setMtTeam] = useState<any>(null);
    const [showJoinTeam, setShowJoinTeam] = useState<boolean>(false);
    const [notificationsDrawer, setNotificationsDrawer] = useState<boolean>(false);
    const [projectTabs, setProjectTabs] = useState<any[]>([]);
    const [tabsLoaded, setTabsLoaded] = useState<boolean>(false);
    const [gcTeam, setGcTeam] = useState<TeamInfo>();
    const [changeProjectAnchor, setChangeProjectAnchor] = useState<null | HTMLElement>(null);
    const [changeTeamAnchor, setChangeTeamAnchor] = useState<null | HTMLElement>(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("guest_token")) {
            setGuestToken(urlParams.get("guest_token"));
        }
        if (urlParams.get("auto_approve_token")) {
            setAutoApproveToken(urlParams.get("auto_approve_token"));
        }
        getInvitedTeam();
    }, []);

    useEffect(() => {
        if (context.currentProjectInfo) {
            buildProjectTabs(context.currentProjectInfo);
        }
    }, [context.currentProjectInfo.use_lead_times]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("event")) {
            updateTabAndCookie("deliveries");
        } else if (props.page) {
            switch (props.page.toLowerCase()) {
                case "portal":
                    updateTabAndCookie("portal");
                    break;
                case "logistics":
                    updateTabAndCookie("logistics");
                    break;
                case "events":
                    updateTabAndCookie("deliveries");
                    break;
                case "lead_times":
                    updateTabAndCookie("procurement");
                    break;
                case "directory":
                    updateTabAndCookie("directory");
                    break;
            }
        } else if (Helper.getCookie("last_project_tab")) {
            updateTabAndCookie(Helper.getCookie("last_project_tab"));
        } else {
            updateTabAndCookie("portal");
        }
    }, [projectTabs]);

    const oldTabLogic = (val: number) => {
        switch (val) {
            case 1:
                updateTabAndCookie("logistics");
                break;
            case 2:
                updateTabAndCookie("deliveries");
                break;
            case 3:
                updateTabAndCookie("procurement");
                break;
            case 4:
                updateTabAndCookie("directory");
                break;
            default:
                updateTabAndCookie("portal");
                break;
        }
    };

    const getInvitedTeam = () => {
        if (Helper.getCookie("invited_team") !== "" && Helper.getCookie("invited_team_id") !== "") {
            const team = Helper.getCookie("invited_team");
            const team_id = Helper.getCookie("invited_team_id");
            const teamInfo = { id: parseInt(team_id), team_name: team };

            setMtTeam(teamInfo);
            setShowJoinTeam(true);
        } else {
            if (context.currentViewedTeamInfo) {
                const t = new TeamInfo(context.currentViewedTeamInfo);
                const teamInfo = { id: t.id, team_name: t.team_name };

                setMtTeam(teamInfo);
                setShowJoinTeam(true);
            }
        }
    };

    const updateTabAndCookie = (val) => {
        if (typeof val === "number") {
            oldTabLogic(val);
        }
        context.setActiveSidebarKey(val);
        Helper.setCookie("last_project_tab", val, 365);
    };

    const navigateToCommandCenter = () => {
        window.location.href = `${Helper.baseUrlWithPort()}/projects/${
            context.currentProjectInfo.unique_token
        }/dashboard`;
    };

    const buildProjectTabs = async (projectInfo: any = context.currentProjectInfo) => {
        const tempProjectTabs = [
            {
                key: "portal",
                component: <ProjectPortal object_id={props.objectId} />,
            },
            {
                key: "logistics",
                component: (
                    <ProjectLogistics
                        object_id={props.objectId}
                        guest_token={guestToken}
                        toggleProjectProfile={setShowProjectProfile((prevState) => !prevState)}
                        showProjectProfile={setShowProjectProfile(true)}
                    />
                ),
            },
            {
                key: "directory",
                component: <ProjectMembers />,
            },
            { key: "settings", component: <ProjectSettings /> },
        ];
        if (projectInfo.use_lead_times) {
            tempProjectTabs.splice(2, 0, {
                key: "procurement",
                component: (
                    <Suspense
                        fallback={<Skeleton variant="rectangular" width={"100%"} height={"100%"} animation="wave" />}
                    >
                        <LeadTimesPage />
                    </Suspense>
                ),
            });
        }
        if (projectInfo.use_deliveries) {
            tempProjectTabs.splice(2, 0, {
                key: "deliveries",
                component: (
                    <Suspense
                        fallback={<Skeleton variant="rectangular" width={"100%"} height={"100%"} animation="wave" />}
                    >
                        <ProjectCalendar />
                    </Suspense>
                ),
            });
        }
        return setProjectTabs(tempProjectTabs);
    };

    useEffect(() => {
        if (projectTabs.length > 0) {
            setTabsLoaded(true);
        }
    }, [projectTabs]);

    return (
        <>
            <Suspense>
                <Content className="wrapper_inner" style={{ margin: "0", height: "100%", borderRadius: "0px" }}>
                    {context.addTeamToProjectModal && <AddTeamToProjectModal />}
                    <InstallPLOTModal
                        visible={installPLOTModalVisible}
                        hideModal={() => setInstallPLOTModalVisible(false)}
                    />
                    {context.gottenUserInfo &&
                        showJoinTeam &&
                        context.userHasPermission(Permission.is_invited, mtTeam?.id, null) && (
                            <AcceptInviteModal
                                team={mtTeam}
                                teamId={mtTeam?.id}
                                teamName={mtTeam?.team_name}
                            ></AcceptInviteModal>
                        )}
                    {autoApproveToken && (
                        <Suspense
                            fallback={
                                <Skeleton variant="rectangular" width={"100%"} height={"100%"} animation="wave" />
                            }
                        >
                            <AcceptAutoJoinToProjectModal auto_approve_token={autoApproveToken} />
                        </Suspense>
                    )}
                    {!context.isMobile ? (
                        <div
                            className={Helper.classNames(
                                "flex flex-row justify-start items-center gap-4 ml-8 mr-4",
                                context.isMobile && "mt-[calc(48px+1rem)]"
                            )}
                        >
                            <div className="flex flex-row items-center">
                                <SidebarControlButton />
                            </div>
                            <div className="truncate page-title">
                                <h1 className="flex flex-row items-center">
                                    {context.currentViewedTeamInfo && (
                                        <IconButton
                                            onClick={() => {
                                                window.location.href = Helper.baseUrlAltSubdomain(
                                                    context.currentViewedTeamInfo.subdomain
                                                );
                                            }}
                                        >
                                            <TeamAvatar team={context.currentViewedTeamInfo} size={48} />
                                        </IconButton>
                                    )}
                                    <span className="ml-4">{context.currentProjectInfo.project_name}</span>
                                </h1>
                                <div className="ml-4">
                                    <ProjectSelect />
                                </div>
                            </div>

                            <div className="flex flex-row items-center gap-4 justify-between">
                                <Suspense
                                    fallback={
                                        <Skeleton
                                            variant="rectangular"
                                            width={"100%"}
                                            height={"100%"}
                                            animation="wave"
                                        />
                                    }
                                >
                                    <PromptJobsiteActiveModal
                                        visible={activePromptVisible}
                                        closeModal={() => {
                                            setActivePromptVisible(false);
                                        }}
                                    />
                                </Suspense>
                            </div>
                            {context.userHasPermission(
                                Permission.is_member,
                                context.currentTeam?.id,
                                context.currentProjectInfo.id
                            ) && (
                                <button className="plot-button primary-button" onClick={navigateToCommandCenter}>
                                    <FontAwesomeIcon icon={faTv} />
                                    <span className="ml-2">Command Center</span>
                                </button>
                            )}
                        </div>
                    ) : (
                        <div className="menu-title">
                            <div className="page-title flex flex-row items-center justify-between gap-4">
                                <SidebarControlButton />

                                <h1 className="flex flex-row items-center w-full">
                                    {context.currentViewedTeamInfo && (
                                        <TeamAvatar team={context.currentViewedTeamInfo} size={48} />
                                    )}

                                    <span className="ml-2">{context.currentProjectInfo.project_name}</span>
                                </h1>
                                {!context.userHasPermission(
                                    Permission.is_member,
                                    context.currentTeam?.id,
                                    context.currentProjectInfo.id
                                ) && <RequestToJoinProject />}
                            </div>
                        </div>
                    )}

                    {tabsLoaded && (
                        <>
                            {projectTabs.find((tab) => tab.key === context.activeSidebarKey)
                                ? projectTabs.find((tab) => tab.key === context.activeSidebarKey).component
                                : projectTabs[0].component}
                        </>
                    )}
                    {/* Notifications are for  */}
                    <NotificationsList />

                    {/* {projectTabs.map((tab, index) => (
                        <TabPanel value={activeIndex} index={index} key={index} className="tab-panel">
                            {tab.component}
                        </TabPanel>
                    ))} */}

                    {context.userInfo && <PromptForNotificationPreferencesModal userInfo={context.userInfo} />}
                </Content>
            </Suspense>
        </>
    );
};

export default Project;
